.navigation {
  list-style-type: none;
  margin: 0;
  height: 100px;
  background-color: #004f45;
  overflow: hidden;
  font-weight:bold;
}
.logo {
  width: 200px;
  height: auto;
  margin-top: 12px;
  float: left;
}
.logo1 {
  width: 50px;
  height: auto;
  float: left;
  margin-top: 15px;
  margin-left: 15px;
}
.hamburger {
  float: right;
  margin-top: 25px;
  margin-right: 20px;
  padding: 10px;
}
.navigation-menu.expanded ul {
  display: block;
}
.navigation-menu ul {
  display: none;
}
.navigation-menu ul {
  position: absolute;
  top: 85px;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-top: 1px solid black;
  z-index: 999; 
}
.navigation-menu li {
  text-align: center;
  margin: 0;
}
.navigation-menu li a {
  color: black;
  width: 100%;
  padding: 18px 0;
  font-size: larger;
}
.navigation-menu li:hover {
  background-color: #eee;
}
.abm {
  height: 26px;
  color: white;
  width: 20%;
  border-radius: 5px;
  border-color: black;
  border-width: 2px;
  
}
