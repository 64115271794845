.srvc {
  background-color: rgb(0, 0, 0);
  padding: 10px;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  color: white;
  text-align: center;
  margin-top: 50px;
}
.mma {
  text-align: center;
  font-size: 22px;
}
.srvc1 {
  background-color: rgb(0, 0, 0);
  padding: 10px;
  width: 600px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  color: white;
  text-align: center;
}
.hgg {
  width: 1400px;
  height: 600px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.faq-container {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  font-size: x-large;
}

.faq-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.faq-column {
  padding: 10px;
  word-wrap: break-word;
}

.faq-question-column {
  width: 25%;
  font-weight: bold;
}

.faq-answer-column {
  width: 75%;
  font-size: large;
  padding: 40px;
}

@media (max-width: 768px) {
  .faq-container {
    text-align: center;
  }

  .faq-row {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .faq-column {
    padding: 10px;
    word-wrap: break-word;
  }
  .srvc,
  .srvc1 {
    width: 250px;
    font-size: 20px;
  }
  .hgg {
    width: 500px;
    height: 340px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .mma {
    font-size: 18px;
  }

  .faq-question-column {
    width: 100%;
    font-weight: bold;
  }

  .faq-answer-column {
    width: 90%;
    font-size: large;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
  }
}
