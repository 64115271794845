.footer {
  background-color: #030303;
  color: white;
  padding: 20px;
  font-size: larger;
}
.logof1 {
  width: 70px;
  height: 80px;
  float: left;
  margin-top: 8px;
  margin-left: 16px;
}
.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* align-items: center; */
}

.column {
  flex: 1 1 250px;
  margin-bottom: 20px;
}

.logof2 {
  width: 200px;
  height: auto;
  padding-top: 20px;
}
.textc {
  padding-left: 25px;
}
ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
}

.icon {
  margin-right: 5px;
  font-size: large;
}

.footer p {
  margin: 0;
}
.line {
  width: 100%;
  height: 1px;
  background-color: white;
}
.footer .made-by {
  margin-left: auto;
}
a {
  display: block;
  color: white;
  text-decoration: none;
}
a:hover {
  color: #00ff11;
}

.ql {
  padding-left: 40px;
}

@media screen and (max-width: 1200px) {
  .footer {
    text-align: center;
  }
  .column {
    flex-basis: 100%; /* Set the column width to 100% on smaller screens */
  }

  .textc {
    padding-top: 25px;
  }
  .logof1 {
    padding-left: 10px;
    height: 80px;
    width: 65px;
    padding-top: 0; /* Remove the top padding on smaller screens */
  }
  .logof2 {
    padding-right: 25px;
  }

  .ql {
    padding-left: 0; /* Remove the left padding on smaller screens */
  }
  .copyright .made-by {
    padding: 0;
  }
}
