.quote {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #ffffff;
}
.ccc{
  margin-bottom: 20px;
}
img {
  height: 690px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    100,
    157,
    176,
    0.504
  ); /* Light color with transparency */
}
.quote h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.quote p {
  font-size: 16px;
}
.srvcc {
  background-color: rgb(0, 0, 0);
  padding: 10px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  color: white;
  text-align: center;
}
@media (max-width: 768px) {
  .quote {
    top: 40%;
    font-size: 10px;
  }

  img {
    height: 450px;
  }

  .srvcc {
    width: 200px;
    font-size: 25px;
  }
}
