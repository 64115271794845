.tle {
  background-color: rgb(0, 0, 0);
  padding: 10px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  color: white;
  margin-top: 50px;
  font-size: 35px;
}
.contact {
  display: flex;
  padding: 30px;
}
.address {
  padding-left: 60px;
  padding-bottom: 20px;
  background-color: #004f45;
  font-size: larger;
  border-radius: 10px;
}
.abcd {
  margin-left: 60px;
}
.contact-form {
  padding-left: 60px;
  background-color: #004f45;
  font-size: larger;
  border-radius: 10px;
}
.form-group {
  padding-top: 20px;
  margin-bottom: 1rem;
}

label {
  display: block;
  color: lightgreen;
  font-weight: bold;
}

input[type="text"],
input[type="tel"],
input[type="email"],
textarea {
  display: block;
  width: 90%;
  font-size: medium;
  padding: 0.5rem;
  background-color: white;
  border: 0px;
  border-radius: 10px;
}
.det {
  font-size: xx-large;
}
button[type="submit"] {
  padding: 0.5rem 1rem;
  width: 545px;
  background-color: #000000;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}
@media only screen and (max-width: 768px) {
  .tle {
    width: 200px;
    font-size: 20px;
  }
  .contact {
    flex-direction: column; /* Stack contact elements vertically */
    padding: 0px;
    text-align: center;
    font-size: 12px;
  }
  .abcd {
    margin-left: 0;
  }
  .address,
  .contact-form {
    padding: 30px; /* Decrease padding for better layout on smaller screens */
  }
  .form-group{
    text-align: left;
  }

  button[type="submit"] {
    width: 100%; /* Make the button span the full width of the screen */
  }
}
