.navbar {
  list-style-type: none;
  margin: 0;
  height: 100px;
  background-color: #004f45;
  overflow: hidden;
}
.logo {
  width: 260px;
  height: auto;
  margin-top: 6px;
  float: left;
}
.logo1 {
  width: 70px;
  height: auto;
  float: left;
  margin-top: 8px;
  margin-left: 25px;
}
.navbar li {
  float: right;
}

.navbar li {
  display: block;
  color: white;
  font-size: 23px;
  font-weight: 500;
  text-align: center;
  padding: 25px 16px;
  width: 125px;
  text-decoration: none;
}
.ab {
  height: 36px;
  background-color: black;
  border-radius: 5px;
  border-color: black;
  border-width: 2px;
}
