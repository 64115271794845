/* ScrollToTopButton.module.css */
.scrollToTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollToTopButton:hover {
  background-color: #555;
}
