.gal {
  background-color: rgb(0, 0, 0);
  padding: 10px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  border-radius: 10px;
  font-size: 35px;
  text-align: center;
  margin-top: 50px;
}
.mma1 {
  text-align: center;
  font-size: 30px;
}
.hg {
  width: 1400px;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.hd {
  text-align: center;
  background-color: rgb(0, 0, 0);
  padding: 10px;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  color: white;
  border-radius: 10px;
  font-size: 40px;
}
.photo-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
}

.photo-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 450px;
}

.photo {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 400px; /* Adjust the maximum height of the photo */
}

.label {
  margin-top: 10px;
  text-align: center;
  font-weight: bold;
}
.faq-aaswer-column {
  width: 75%;
  font-size: large;
  padding: 40px;
  margin-top: 50px;
}
.forf {
  margin-left: 170px;
}
@media (max-width: 768px) {
  .gal {
    width: 170px;
    font-size: 20px;
  }
  .hd {
    width: 250px;
    font-size: 20px;
  }
  .mma1 {
    font-size: 18px;
  }
  .forf {
    margin-left: 0px;
  }
  .faq-aaswer-column {
    width: 90%;
    font-size: large;
    margin-left: 10px;
    padding: 0px;
  }
}
