.card {
  width: 600px;
  padding: 20px;
  color: black;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  margin-left: auto;
  margin-right: auto;
}

.h11 {
  margin-top: 0;
  text-align: center;
}

.i1 {
  width: 600px;
  height: 400px;
  background-size: cover;
  background-position: center;
}
@media screen and (max-width: 768px) {
  .card {
    width: 80%;
  }
  .h11{
    font-size: 20px;
  }
  .i1 {
    height: 200px;
    width: auto;
  }
}
