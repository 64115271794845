/* Assuming you have a global CSS or SCSS file */

.error-page {
  text-align: center;
  margin-top: 100px;
}

.error-page h2 {
  font-size: 36px;
  color: #ff0000;
}

.error-page p {
  font-size: 18px;
  color: #555;
}

.error-page a {
  display: block;
  margin-top: 20px;
  margin-bottom: 54px;
  font-size: 16px;
  color: #007bff;
  text-decoration: none;
}

.error-page a:hover {
  text-decoration: underline;
}
