.about-us-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.srvcg {
  background-color: rgb(0, 0, 0);
  padding: 10px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  color: white;
  text-align: center;
  margin-top: 50px;
  font-size: 35px;
}
.srvc2 {
  background-color: rgb(0, 0, 0);
  padding: 10px;
  width: 250px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  color: white;
  text-align: center;
  margin-top: 50px;
  font-size: 35px;
}

.section {
  margin-bottom: 40px;
}

.title-box h2 {
  color: white;
  font-size: 30px;
  margin-bottom: 10px;
  margin-left: 51px;
}

.title-box h5 {
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: 51px;
  color: #fffde4;
}

.title-box p {
  margin-bottom: -17px;
  margin-left: 51px;
  color: #fffde4;
}

img {
  max-width: 100%;
  /* height: auto; */
}

.question-answer {
  display: flex;
  justify-content: space-between;
}

.question {
  flex: 1;
}

.answer {
  flex: 2;
}

.visionPart h3 {
  color: white;
  font-size: 30px;
  margin-bottom: 10px;
  margin-left: 19px;
}

.missionPart h3 {
  color: white;
  font-size: 30px;
  margin-left: 19px;
  margin-bottom: 10px;
}

.aboutPara {
  margin-top: 10px;
  display: contents;
}

.team-members {
  display: flex;
  justify-content: space-between;
}

.member {
  flex: 1;
  text-align: center;
}

.member img {
  max-width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.aboutUsContainer {
  margin-top: 70px;
  margin-bottom: 60px;
  padding: 20px;
  background-color: #004f45;
  height: 548px;
}

.about-section {
  background-color: #f1f1f1;
  padding: 20px;
}

.missionPart {
  padding: 10px;
}

.visionPart {
  padding: 10px;
}

.mission {
  display: block;
  padding: 20px;
  float: left;
  width: 699px;
}

.aboutPart {
  height: 100px;
}

.missionVision {
  padding: 27px;
}

.missionData p {
  margin-left: 31px;
  color: #fffde4;
}
.vissionData p {
  margin-left: 31px;
  color: #fffde4;
}
.aname {
  font-size: 35px;
  color: white;
  background-color: #004f45;
  padding: 8px;
  padding-bottom: 10px;
  border-radius: 25px;
  width: 335px;
}
.temname {
  text-align: center;
}
.tm {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1200px) {
  .mission {
    width: 330px;
    padding: 3px;
    float: none;
  }

  .aboutUsContainer {
    height: fit-content;
    text-align: center;
  }
  .about-section,
  .missionPart,
  .visionPart {
    padding: 10px;
    word-wrap: break-word;
    margin-left: 0;
  }

  .temname {
    text-align: center;
  }
  .tm {
    width: 250px;
  }
  .question-answer {
    display: block;
  }
  .srvc2,
  .srvcg {
    width: 200px;
    font-size: 25px;
  }
  .aname {
    font-size: 18px;
    color: white;
    background-color: #004f45;
    padding: 10px;
    border-radius: 25px;
    width: 180px;
  }

  .title-box p,
  .title-box h2,
  .title-box h5,
  .missionData p,
  .missionPart h3,
  .visionPart h3,
  .vissionData p {
    margin-left: 0px;
  }
}
